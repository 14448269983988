import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "../../components/layout"
import { SEO } from "../../components/seo"

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      daniel: file(relativePath: { eq: "foraldrar/daniel.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Kontakt oss" />
      <Layout>
        <div className="relative bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-warm-grey-900 sm:text-4xl sm:leading-10">
                Tack!
              </h2>
              <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-warm-grey-500 sm:mt-4">
                Vi kommer höra av oss till dig så fort vi kan.
              </p>
            </div>
            <section className="overflow-hidden mt-16 lg:mt-24">
              <div className="relative max-w-screen-xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
                <svg className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                  <defs>
                    <pattern id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="784" height="404" fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
                </svg>

                <svg className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
                  <defs>
                    <pattern id="56409614-3d62-4985-9a10-7ca758a8f4f0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="404" height="784" fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
                </svg>

                <div className="relative lg:flex lg:items-center">
                  <div className="hidden lg:block lg:flex-shrink-0">
                    <Img className="h-64 w-64 rounded-full xl:h-80 xl:w-80" fluid={data.daniel.childImageSharp.fluid} />
                  </div>

                  <div className="relative lg:ml-10">
                    <svg className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-bb-red-200 opacity-50" stroke="currentColor" fill="none" viewBox="0 0 144 144">
                      <path stroke-width="2" d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z" />
                    </svg>
                    <blockquote>
                      <div className="text-2xl leading-9 font-medium text-warm-grey-900">
                        <p>
                          Sonen älskar maten och det känns bra att vi kan prioritera mat av hög kvalitet.
                        </p>
                      </div>
                      <footer className="mt-8">
                        <div className="flex">
                          <div className="flex-shrink-0 lg:hidden">
                            <Img className="h-12 w-12 rounded-full" fluid={data.daniel.childImageSharp.fluid} />
                          </div>
                          <div className="ml-4 lg:ml-0">
                            <div className="text-base leading-6 font-medium text-warm-grey-900">Daniel</div>
                            <div className="text-base leading-6 font-medium text-bb-red-600">Pappa till Ebba</div>
                          </div>
                        </div>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  )
}



export default Page
